import React from "react";
import { Link, Typography, useTheme } from "@material-ui/core";
import Button from "components/Button";
import ErrorPage from "pages/ErrorPage";
import { getReturnUrl } from "utils/UrlUtil";
import useServiceName from "hook/useServiceName";
import { useTranslation } from "react-i18next";

const ErrorInactiveSiret = () => {
  const serviceName = useServiceName();
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <ErrorPage
      title={t("errorInactiveSiret:title")}
      subtitle={t("errorInactiveSiret:subTitle")}
      action={
        <>
          <Link
            style={{ width: "100%" }}
            href={process.env.REACT_APP_ADV_MISSING_INFO}
            underline="none"
          >
            <Button>{t("errorInactiveSiret:cta")}</Button>
          </Link>
          <Link
            style={{
              width: "100%",
              marginTop: "1rem",
              display: "flex",
              justifyContent: "center",
            }}
            href={getReturnUrl()}
            underline="none"
          >
            <Typography
              variant="body2"
              style={{ fontWeight: 700, color: theme.palette.cerulean }}
            >
              {t("common:goBackTo")} {serviceName}
            </Typography>
          </Link>
        </>
      }
      imgSrc="/certification.png"
    />
  );
};

export default ErrorInactiveSiret;
