import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/styles/makeStyles";
import { Box, Grid, Typography, Link as LinkECP } from "@material-ui/core";
import Step from "components/Layout/Step";
import Modal from "components/Modal";
import Actions from "components/Layout/Actions";
import Link from "components/Link";
import Button from "components/Button";
import MainContent from "components/Layout/MainContent";
import { deleteIdFiles } from "utils/FileUtil";
import { getReturnUrl } from "utils/UrlUtil";
import IdErrorIcon from "icons/id/error/idError";
import IdErrorModalContent from "components/IdErrorModalContent";
import CenteredTitle from "components/Layout/CenteredTitle";
import Illustration from "components/Layout/Illustration";
import { useTranslation } from "react-i18next";
import { getUploadTries, setUploadTries } from "utils/UploadFileUtil";

const useStyles = makeStyles((theme) => ({
  idErrorIconContainer: {
    width: "100px",
    height: "100px",
    "& svg": {
      width: "100px",
      height: "100px",
      fill: "none",
    },
  },
  idErrorContent: {
    "& h2, p": {
      textAlign: "center",
    },
  },
  headContainer: {
    textAlign: "center",
  },
  alertIcon: {
    height: 24,
    width: 24,
    margin: theme.spacing(2),
  },
  subtitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    lineHeight: "20px",
  },
  grayContainer: {
    backgroundColor: theme.palette["-body-background-charte-gris-2"],
    borderRadius: 4,
    padding: 14,
  },
  idIcon: {
    width: 74,
    height: 54,
  },
  idsRowContainer: {
    marginBottom: theme.spacing(2),
  },
  idLabel: {
    color: theme.palette["background-dark"],
    margin: `${theme.spacing(1)}px auto 0`,
    textAlign: "center",
    fontWeight: 500,
  },
  modalLink: {
    marginTop: "20px",
    textAlign: "right",
    cursor: "pointer",
    fontWeight: 700,
    color: theme.palette.cerulean,
  },
  backLink: {
    fontWeight: 700,
    color: theme.palette.cerulean,
  },
}));

const IdIllegible = (props) => {
  const { title, label, btnLabel, displayModalButton } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);

  const handleClickCloseModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  useEffect(() => {
    deleteIdFiles();
    setUploadTries(getUploadTries() + 1);
  }, []);

  return (
    <>
      <Step progress={54}>
        <Illustration>
          <Box className={classes.idErrorIconContainer}>
            <IdErrorIcon />
          </Box>
        </Illustration>
        <CenteredTitle>{title}</CenteredTitle>
        <MainContent>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.idErrorContent}
          >
            {label && <Typography variant="body1">{label}</Typography>}
          </Grid>
          {displayModalButton && (
            <Box mt={2}>
              <LinkECP
                onClick={() => setModalOpen(true)}
                className={classes.modalLink}
              >
                <Typography variant="body2" className={classes.backLink}>
                  &gt; {t("idIllegible:infoReadableDocument.title")}
                </Typography>
              </LinkECP>
            </Box>
          )}
        </MainContent>
        <Actions>
          <Box mb={2} style={{ width: "100%" }}>
            <Link
              to="/choix-piece-identite"
              underline="none"
              data-testid="test-link"
            >
              <Button>{btnLabel}</Button>
            </Link>
          </Box>
          <LinkECP href={getReturnUrl()} className={classes.backLink}>
            <Typography variant="body2" className={classes.backLink}>
              {t("idIllegible:certifyLater")}
            </Typography>
          </LinkECP>
        </Actions>
      </Step>
      <Modal
        title={t("idIllegible:infoReadableDocument.title")}
        onClose={handleClickCloseModal}
        open={modalOpen}
      >
        <IdErrorModalContent />
      </Modal>
    </>
  );
};

export default IdIllegible;

IdIllegible.propTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string,
  displayModalButton: PropTypes.bool,
  btnLabel: PropTypes.string.isRequired,
};

IdIllegible.defaultProps = {
  label: null,
  displayModalButton: false,
};
